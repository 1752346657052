import React from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { Avatar } from '@components/ui/Avatar'
import { Input } from '@components/ui/forms/fields/Input'
import {
  useAccountStore,
  useNewPostStore,
} from '@sodium/shared-frontend-schemas'
import { CreatePostDialog } from './CreatePostDialog'

interface HomePostInputProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
}

export const HomePostInput: React.FC<HomePostInputProps> = ({
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const { reset } = useNewPostStore()
  const { selectedAccount } = useAccountStore()
  const { user } = useAuth()

  const handlePress = () => {
    reset()
    setIsDialogOpen(true)
  }

  return (
    <div className="space-y-3 bg-white p-3 py-6 dark:border-none">
      <div className="flex items-center space-x-2 align-middle">
        <Avatar
          uri={selectedAccount?.profilePicture || user?.profilePicture}
          size={43}
        />

        <Input
          name="create-post"
          placeholder="Have a topic that excites you? Post about it"
          className="h-[43px] cursor-pointer"
          style={{ borderRadius: '20px', cursor: 'pointer' }}
          onClick={handlePress}
        />
      </div>
      <CreatePostDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </div>
  )
}
