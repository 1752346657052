import React, { useEffect, useState } from 'react'
import { defaultProfileImg } from '@components/ui/images/images'

export const Suggestions: React.FC = () => {
  const [suggestions, setSuggestions] = useState<any[]>([])

  useEffect(() => {
    const suggestions = new Array(4).map((_, i) => ({
      userId: `userid_${i}`,
      username: 'username',
      email: 'email@email.com',
      avatar: defaultProfileImg,
      password: '12312321321',
      birthdate: '22/03/2023',
      registeredAt: '22/03/2023',
      company: 'someCOmpany',
      id: i,
    }))
    setSuggestions(suggestions)
    // console.log(suggestions);
  }, [])

  return (
    <div className="hidden space-y-2 xl:inline">
      <div className="m-auto w-11/12 space-y-2 overflow-hidden rounded-lg bg-white py-2.5 dark:border-none">
        <div className="nb-d-5 flex justify-between text-sm">
          <h3 className="m-2 text-sm font-bold text-gray-900">
            Add to your feed
          </h3>
        </div>
        {suggestions.map((profile) => (
          <div key={profile.userId}>
            <div className="m-2 mb-3 flex items-center justify-between">
              <img
                className="h-10 w-10 rounded-full border p-[2px]"
                src={profile.avatar}
                alt=""
              />
              <div className="ml-4 flex-1">
                <h2 className="text-sm font-semibold">{profile.username}</h2>
                <h3 className="text-xs text-gray-400">{profile.company}</h3>
              </div>
            </div>
            <div className="flex justify-center" key={profile.password}>
              <button className="-mt-2 rounded-full border border-gray-500 bg-transparent px-4 py-0.5 text-sm font-bold text-gray-600 hover:bg-gray-200">
                + Follow
              </button>
            </div>
          </div>
        ))}
      </div>
      <hr />
      {/* Ads */}
      <div className="sticky top-20 m-auto h-64 w-11/12 rounded-lg border border-gray-300 bg-white px-2.5 dark:border-none">
        <div className="relative h-full w-full">
          <img src="https://media.licdn.com/media/AAYQAgTPAAgAAQAAAAAAADVuOvKzTF-3RD6j-qFPqhubBQ.png" />
        </div>
      </div>
    </div>
  )
}
