import type { MarketplaceAsset } from '@sodium/shared-schemas'

export const formatterAssets = (
  assets: MarketplaceAsset[],
): MarketplaceAsset[] => {
  return assets.map((asset) => ({
    id: asset.id,
    url: asset.url ?? '',
    thumbnailUrl: asset.thumbnailUrl ?? '',
    originalName: asset.originalName ?? '',
    mimeType: asset.mimeType ?? '',
    isImage: asset.isImage ?? false,
    isVideo: asset.isVideo ?? false,
    isFile: asset.isFile ?? false,
    height: asset.height ?? 0,
    width: asset.width ?? 0,
    isVertical: asset.isVertical ?? false,
    sizeBytes: asset.sizeBytes ?? 0,
    duration: asset.duration ?? 0,
    pages: asset.pages ?? 0,
    ownerId: asset.ownerId ?? undefined,
    storyId: asset.storyId ?? undefined,
    postId: asset.postId ?? undefined,
    activityId: asset.activityId ?? undefined,
  }))
}
