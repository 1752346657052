import type { ImageUploadProps } from '@api/uploads'
import type { VideoUploadProps } from '@components/ui/video/VideoUpload'
import type { MarketplaceUploadHookResponse } from '@src/types/assets'
import { useCallback, useEffect, useRef } from 'react'
import { backendHook } from '@api/trpc'
import { Promises } from '@goatlab/js-utils'
import { toastSonner } from '@goatlab/react-ui'
import { gcpService } from '@src/services/gcp/gcp.service'
import {
  defaultMarketplaceAssetUploadState,
  useUploadState,
} from '@src/types/assets'
import { imageService } from '@src/utils/image/image.service'
import { VideoService } from '@src/utils/video/video.service'
import { assetMetadataFromBlobImage, processImages } from './useImageUploads'
import { assetMetadataFromBlobVideo } from './useVideoUploads'

export const useImageOrVideoUploads = (): MarketplaceUploadHookResponse => {
  const [assetUploadState, setAssetUploadState] = useUploadState()
  const imageOrVideoBlobRef = useRef<Blob | null>(null)
  const thumbnailBlobRef = useRef<Blob | null>(null)
  const videoServiceRef = useRef<VideoService | null>(null)

  const getUploadLinkHook =
    backendHook.backend.posts.getUploadSession.useMutation()

  const reset = useCallback(() => {
    setAssetUploadState(defaultMarketplaceAssetUploadState)
  }, [setAssetUploadState])

  useEffect(() => {
    if (videoServiceRef?.current) {
      return
    }
    videoServiceRef.current = new VideoService()

    return () => {
      if (videoServiceRef) {
        videoServiceRef.current = null
      }
    }
  }, [videoServiceRef])

  const uploadImageOrVideoProcessedAsset = useCallback(
    async (imageOrVideoUploadBlob: VideoUploadProps) => {
      reset()
      setAssetUploadState((state) => ({
        ...state,
        assetSelectionTriggered: true,
      }))
      try {
        if (imageOrVideoUploadBlob.mime.includes('image')) {
          const imageUploadBlob: ImageUploadProps = {
            fileName: imageOrVideoUploadBlob.fileName,
            imageBlob: imageOrVideoUploadBlob.videoBlob,
            mime: imageOrVideoUploadBlob.mime,
          }
          imageOrVideoBlobRef.current = imageUploadBlob.imageBlob // Validate if not working assign directly
          const assetWithFileImage =
            await assetMetadataFromBlobImage(imageUploadBlob)

          if (!assetWithFileImage) {
            return reset()
          }

          const { asset: assetMetadata, imageFile } = assetWithFileImage

          setAssetUploadState((state) => ({
            ...state,
            localAssetUrl: assetMetadata.url,
            assetPreviewImage: assetMetadata.url,
            isProcessingAsset: true,
            assetProcessingProgress: 0,
            assetMetadata,
          }))

          const { resultImage, compressedThumbnail } =
            await processImages(imageFile)

          thumbnailBlobRef.current = compressedThumbnail.compressedBlob

          const [links, linksThumbnail] = await Promise.all([
            getUploadLinkHook.mutateAsync({
              fileName: assetMetadata.originalName,
            }),
            getUploadLinkHook.mutateAsync({
              fileName: assetMetadata.originalName,
              visibility: 'public',
            }),
          ])

          setAssetUploadState((state) => ({
            ...state,
            assetProcessingProgress: 1,
            isProcessingAsset: false,
            processedAssetUri: resultImage.compressedUrl,
            finalAssetUrl: links.url,
            signedUploadUrl: links.signedUploadURL,
            processedThumbnailAssetUri: compressedThumbnail.compressedUrl,
            signedUploadThumbnailUrl: linksThumbnail.signedUploadURL,
            assetMetadata: {
              ...assetMetadata,
              thumbnailUrl: linksThumbnail.url,
            },
          }))
        } else if (imageOrVideoUploadBlob.mime.includes('video')) {
          imageOrVideoBlobRef.current = imageOrVideoUploadBlob.videoBlob
          const assetMetadata = assetMetadataFromBlobVideo(
            imageOrVideoUploadBlob,
          )

          const originalName = assetMetadata.originalName

          const thumbnailName = originalName.replace(/\.\w+$/, '.jpg')

          setAssetUploadState((state) => ({
            ...state,
            localAssetUrl: assetMetadata.url,
            assetPreviewImage: assetMetadata.url,
            isProcessingAsset: true,
            assetProcessingProgress: 0,
            assetMetadata,
          }))

          const { videoThumbnailFile } = await Promises.props({
            videoThumbnailFile: videoServiceRef.current?.getThumbnailFile({
              videoBlob: imageOrVideoBlobRef.current,
              timeSeconds: imageOrVideoUploadBlob.videoSettings.customStartTime,
            }),
          })

          if (videoThumbnailFile) {
            thumbnailBlobRef.current = videoThumbnailFile?.thumbnailBlob
          }

          const start = performance.now()

          const { convertedVideo } = await Promises.props({
            convertedVideo: videoServiceRef.current?.convertInBackground(
              {
                videoUploadBlob: {
                  ...imageOrVideoUploadBlob,
                  videoBlob: imageOrVideoBlobRef.current,
                  fileName: originalName,
                },
                width: videoThumbnailFile?.dimensions.width,
                height: videoThumbnailFile?.dimensions.height,
              },
              (status) => {
                setAssetUploadState((state) => {
                  return {
                    ...state,
                    isProcessingAsset: true,
                    localAssetUrl: assetMetadata.url,
                    assetProcessingProgress: status.progress,
                  }
                })

                if (status.progress <= 1) {
                  setAssetUploadState((state) => {
                    return { ...state, isProcessingAsset: true }
                  })
                }

                if (status.progress === 1) {
                  setAssetUploadState((state) => {
                    return { ...state, isProcessingAsset: true }
                  })
                }

                if (status.currentFramePreview) {
                  setAssetUploadState((state) => {
                    return {
                      ...state,
                      assetPreviewImage: status.currentFramePreview,
                      isProcessingAsset: true,
                    }
                  })
                }
              },
            ),
          })

          console.log(
            `Execution time: ${(performance.now() - start) / 1000} seconds`,
          )

          if (convertedVideo) {
            imageOrVideoBlobRef.current = convertedVideo?.outputVideoBlob
          }

          const { thumbnailCompressedImage, thumbnailLinks, videoLinks } =
            await Promises.props({
              thumbnailCompressedImage: videoThumbnailFile
                ? imageService.resizeAndCompressImage({
                    imageFile: videoThumbnailFile.file,
                    type: 'thumbnail',
                  })
                : undefined,
              thumbnailLinks: getUploadLinkHook.mutateAsync({
                fileName: `${thumbnailName}`,
                visibility: 'public',
              }),
              videoLinks: getUploadLinkHook.mutateAsync({
                fileName: `${convertedVideo?.originalFileName}.mp4`,
              }),
            })

          setAssetUploadState((state) => {
            return {
              ...state,
              isProcessingAsset: false,
              signedUploadUrl: videoLinks.signedUploadURL,
              finalAssetUrl: videoLinks.url,
              processedAssetUri: convertedVideo?.outputVideoUri,
              processedThumbnailAssetUri:
                thumbnailCompressedImage?.compressedUrl,
              signedUploadThumbnailUrl: thumbnailLinks.signedUploadURL,
              assetMetadata: {
                ...assetMetadata,
                sizeBytes: imageOrVideoBlobRef.current?.size,
                thumbnailUrl: thumbnailLinks.url,
                width: videoThumbnailFile?.dimensions.width,
                height: videoThumbnailFile?.dimensions.height,
                isVertical:
                  (videoThumbnailFile?.dimensions.height || 0) >
                  (videoThumbnailFile?.dimensions.width || 0),
              },
            }
          })
        }
      } catch (err) {
        console.error(err)
        toastSonner.error('Error uploading')
        reset()
      }
    },
    [getUploadLinkHook, reset],
  )
  useEffect(() => {
    if (
      assetUploadState.isProcessingAsset ||
      assetUploadState.isUploadingAsset ||
      assetUploadState.success ||
      assetUploadState.assetProcessingProgress !== 1
    ) {
      return
    }

    const uploadAsset = async () => {
      setAssetUploadState((state) => ({ ...state, isUploadingAsset: true }))
      try {
        if (!imageOrVideoBlobRef.current || !thumbnailBlobRef.current) {
          throw new Error('No image blob')
        }

        await Promise.all([
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadUrl || '',
            blob: imageOrVideoBlobRef.current,
            mime: assetUploadState.assetMetadata?.mimeType || '',
          }),
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadThumbnailUrl || '',
            blob: thumbnailBlobRef.current,
            mime: 'image/jpeg',
          }),
        ])

        setAssetUploadState((state) => ({
          ...state,
          isProcessingAsset: false,
          isUploadingAsset: false,
          success: true,
          done: true,
        }))
      } catch {
        toastSonner.error('Could not upload video')
        setAssetUploadState((state) => ({
          ...state,
          isUploadingAsset: false,
          success: false,
          done: true,
        }))
      }
    }

    if (
      assetUploadState.assetProcessingProgress === 1 &&
      assetUploadState.processedAssetUri &&
      assetUploadState.signedUploadUrl &&
      assetUploadState.processedThumbnailAssetUri &&
      assetUploadState.signedUploadThumbnailUrl &&
      !assetUploadState.isUploadingAsset &&
      !assetUploadState.success
    ) {
      void uploadAsset()
    }
  }, [assetUploadState])

  const shouldDisplayPreview =
    assetUploadState.isProcessingAsset || assetUploadState.isUploadingAsset

  return {
    uploadImageOrVideoProcessedAsset,
    reset,
    shouldDisplayPreview,
    ...assetUploadState,
  }
}
