import React, { useEffect, useState } from 'react'
import { backendHook } from '@api/trpc'
import { useHandleFileUpload } from '@components/account/home/CreatePostDialog'
import { AssetViewer } from '@components/account/post/assetViewer/AssetViewer'
import { Button } from '@components/ui/buttons/Button2'
import { ImageOrVideoUpload } from '@components/ui/images/ImageOrVideoUpload'
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  toastSonner,
} from '@goatlab/react-ui'
import { useNewPostStore } from '@sodium/shared-frontend-schemas'
import { useImageOrVideoUploads } from '@src/hooks/uploads/useImageOrVideoUploads'
import { useIs } from '@src/hooks/useIs'

type UploadType = 'imageOrVideo'

interface CreateStoryDialogProps {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
}

export const CreateStoryDialog: React.FC<CreateStoryDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const { isMobile } = useIs()
  const [uploadType, setUploadType] = useState<UploadType | null>(null)

  const {
    addAsset,
    replaceAsset,
    assets,
    reset,
    isLoadingMedia,
    isPosting,
    hasAssets,
    postHasContent,
    setIsLoadingMedia,
  } = useNewPostStore()

  const createStory = backendHook.backend.stories.createStory.useMutation()
  const utils = backendHook.useUtils()

  const isReadyToPost =
    !isPosting && !isLoadingMedia && (hasAssets || postHasContent)

  const imageOrVideoUploadHook = useImageOrVideoUploads()

  useHandleFileUpload(imageOrVideoUploadHook, assets, replaceAsset, addAsset)

  const isHookLoadingMedia =
    imageOrVideoUploadHook.shouldDisplayPreview ||
    assets.some(
      (a) => !(a.url.includes('https://') || a.url.includes('http://')),
    )

  useEffect(() => {
    if (isHookLoadingMedia !== isLoadingMedia) {
      setIsLoadingMedia(isHookLoadingMedia)
    }
  }, [isHookLoadingMedia])

  // Abrir el dialog de subida automáticamente si no hay assets al abrir el modal
  useEffect(() => {
    if (isDialogOpen && !assets.length) {
      setUploadType('imageOrVideo')
    }
  }, [isDialogOpen, assets.length])

  const handleStoryUpload = async () => {
    if (!assets) return
    toastSonner.promise(
      (async () => {
        await createStory.mutateAsync({
          asset: assets[0],
        })
        await utils.backend.stories.getStories.invalidate()
      })(),
      {
        position: isMobile ? 'top-center' : 'top-right',
        loading: 'Creating your story...',
        success: () => {
          setIsDialogOpen(false)
          reset()
          return 'Your story has been created'
        },
        error: (error) => {
          console.error('error??', error)
          return 'There was an error creating your story'
        },
      },
    )
  }

  return (
    <Credenza
      open={isDialogOpen}
      onOpenChange={(opened) => {
        setIsDialogOpen(opened)
        if (!opened) {
          reset()
          setUploadType(null)
        }
      }}
    >
      <CredenzaContent
        aria-describedby="New Story"
        onInteractOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        className="flex max-h-[calc(100dvh)] min-h-[calc(100dvh)] flex-col justify-start md:max-h-[calc(85dvh)] md:min-h-[calc(85dvh)]"
      >
        <CredenzaHeader className="flex w-full flex-row items-center justify-between pb-2">
          <div className="flex-1" />{' '}
          {/* Espacio vacío a la izquierda para centrar el título */}
          <CredenzaTitle className="flex-0 text-center">
            New Story
          </CredenzaTitle>
          <div className="flex flex-1 justify-end">
            {isMobile && (
              <Button
                onClick={async () => {
                  if (!assets.length) return setUploadType('imageOrVideo')
                  await handleStoryUpload()
                }}
                disabled={!!uploadType && (!isReadyToPost || isPosting)}
              >
                {isLoadingMedia
                  ? '...loading'
                  : !assets.length
                    ? 'Add Story'
                    : 'Share'}
              </Button>
            )}
          </div>
        </CredenzaHeader>
        <CredenzaBody className="flex-1">
          <div className="max-w-[555px]">
            {!!assets.length && (
              <AssetViewer
                assets={assets}
                isViewable={true}
                postId={''}
                isPreview={true}
              />
            )}
          </div>
        </CredenzaBody>
        {!isMobile && (
          <CredenzaFooter className="sticky -bottom-7 flex w-full flex-col gap-2 border-t bg-white px-4 py-2">
            <div className="flex w-full justify-end pr-2 pt-2">
              {!isMobile && (
                <Button
                  onClick={async () => {
                    if (!assets.length) return setUploadType('imageOrVideo')
                    await handleStoryUpload()
                  }}
                >
                  {!assets.length
                    ? 'Add Story'
                    : isLoadingMedia
                      ? '...loading'
                      : 'Share'}
                </Button>
              )}
            </div>
          </CredenzaFooter>
        )}
      </CredenzaContent>
      <Dialog
        open={!!uploadType && isDialogOpen}
        onOpenChange={(open) => {
          if (!open) {
            setUploadType(null)
            // Limpiar URLs de video si existen
            if (assets[0]?.url?.startsWith('blob:')) {
              URL.revokeObjectURL(assets[0].url)
            }
          }
        }}
      >
        <DialogContent className="md:min-w-[850px]">
          <DialogHeader>
            <DialogTitle>Upload video or image</DialogTitle>
          </DialogHeader>

          <div className="w-full flex-1">
            <ImageOrVideoUpload
              onUploadButtonPressed={async (data) => {
                await imageOrVideoUploadHook.uploadImageOrVideoProcessedAsset?.(
                  data,
                )
                setUploadType(null) // Cerrar el dialog tras la subida
                if (data.mime.startsWith('video/') && data.videoSettings.url) {
                  URL.revokeObjectURL(data.videoSettings.url)
                }
              }}
              isLoading={isLoadingMedia}
              cropResizable={true}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Credenza>
  )
}
