'use client'

import type { RouterOutputs } from '@sodium/shared-frontend-services'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button } from '@components/ui/buttons/Button2'
import { Input } from '@components/ui/forms/fields/Input'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { YesNoModal } from '@components/ui/modal/YesNoModal'
import { useZodFormHook } from '@goatlab/react-zod-form'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { defaultAccountImage } from '@src/utils/constants'
import { useWindowSize } from '@src/utils/use-window-size'
import { motion } from 'framer-motion'
import {
  Heart,
  Pause,
  Play,
  Send,
  Trash2,
  Volume2,
  VolumeX,
  X,
} from 'lucide-react'
import Stories from 'react-insta-stories'
import { z } from 'zod'

type Story = RouterOutputs['backend']['stories']['getStories']['data'][number]

interface StoryViewerProps {
  stories: Story[]
  initialAccountStoryIndex: number
  onClose: () => void
  onStoryComplete: (index: number) => void
  onLike: (storyId: string) => void
  onReply: (storyId: string, comment: string, userId: string) => void
  onDelete: (storyId: string) => void
}

export interface StoryViewerRef {
  handleNextStory: () => void
}

const commentSchema = z.object({
  comment: z.string(),
})

const StoryViewer = forwardRef<StoryViewerRef, StoryViewerProps>(
  (
    {
      stories,
      initialAccountStoryIndex,
      onClose,
      onStoryComplete,
      onLike,
      onReply,
      onDelete,
    },
    ref,
  ) => {
    const [accountStoryIndex, setAccountStoryIndex] = useState(
      initialAccountStoryIndex,
    )
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0)
    const [isPaused, setIsPaused] = useState(false)
    const [isMuted, setIsMuted] = useState(true)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [_, setShowLikeAnimation] = useState(false)
    const [__, setShowGestureHint] = useState(true)
    const { height, width } = useWindowSize()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { selectedAccount } = useAccountStore()

    const currentAccount = stories[accountStoryIndex]
    const currentStory = currentAccount?.stories[currentStoryIndex]

    const formHook = useZodFormHook({
      schema: commentSchema,
      defaultValues: { comment: '' },
    })

    const isOwner = useMemo(() => {
      return selectedAccount?.id === currentAccount?.accountId
    }, [currentAccount, selectedAccount])

    const handleNextStory = useCallback(() => {
      if (!currentAccount?.stories) return

      if (currentStoryIndex < currentAccount.stories.length - 1) {
        onStoryComplete(currentStoryIndex)
        setCurrentStoryIndex((i) => i + 1)
      } else if (accountStoryIndex < stories.length - 1) {
        onStoryComplete(currentStoryIndex)
        setCurrentStoryIndex(0)
        setAccountStoryIndex((i) => i + 1)
      } else {
        onStoryComplete(currentStoryIndex)
        onClose()
      }
    }, [
      currentStoryIndex,
      currentAccount?.stories,
      accountStoryIndex,
      stories.length,
      onStoryComplete,
      onClose,
    ])

    const handlePrevStory = useCallback(() => {
      if (currentStoryIndex > 0) setCurrentStoryIndex((i) => i - 1)
    }, [currentStoryIndex])

    const handleLike = useCallback(() => {
      if (!currentStory?.id) return
      setShowLikeAnimation(true)
      onLike(currentStory.id)
      setTimeout(() => setShowLikeAnimation(false), 1000)
    }, [currentStory?.id, onLike])

    const handleReply = useCallback(() => {
      if (!currentStory) return
      const comment = formHook.getValues('comment')
      if (!comment.trim()) return
      onReply(currentStory.id, comment, currentStory.accountId)
      formHook.reset()
      inputRef.current?.blur()
      setIsPaused(false)
    }, [currentStory, formHook, onReply])

    const handleDelete = useCallback(() => {
      if (!currentStory?.id) return

      const currentIndex = currentStoryIndex
      const storiesLength = currentAccount.stories.length

      onDelete(currentStory.id)

      if (storiesLength === 1) {
        if (accountStoryIndex < stories.length - 1) {
          setCurrentStoryIndex(0)
          setAccountStoryIndex((i) => i + 1)
        } else {
          onClose()
        }
      } else if (currentIndex === storiesLength - 1) {
        setCurrentStoryIndex((i) => i - 1)
      }
    }, [
      currentStory?.id,
      currentStoryIndex,
      currentAccount?.stories,
      accountStoryIndex,
      stories.length,
      onDelete,
      onClose,
    ])

    const handleOpenDeleteDialog = useCallback(() => {
      setIsDeleteDialogOpen(true)
      setIsPaused(true)
    }, [])

    const handleConfirmDelete = useCallback(() => {
      handleDelete()
    }, [handleDelete])

    const handleCancelDelete = useCallback(() => {
      setIsPaused(false)
    }, [])

    const handleOutsideClick = useCallback((e: React.MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        inputRef.current.blur()
        setIsPaused(true)
      }
    }, [])

    useEffect(() => {
      const timer = setTimeout(() => setShowGestureHint(false), 15000)
      return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') handlePrevStory()
        else if (e.key === 'ArrowRight') handleNextStory()
        else if (e.key === 'Escape') onClose()
      }
      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)
    }, [handlePrevStory, handleNextStory, onClose])

    useImperativeHandle(ref, () => ({ handleNextStory }))

    if (!currentAccount?.stories?.length) {
      onClose()
      return null
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/90 backdrop-blur-lg"
        onClick={onClose}
      >
        <motion.div
          initial={{ y: 0, scale: 1, opacity: 1 }}
          animate={{ y: 0, scale: 1, opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          drag="y"
          dragConstraints={{ top: 0, bottom: 0 }}
          dragElastic={0.5}
          dragMomentum={false}
          onDragEnd={(_, info) => {
            if (info.velocity.y > 400 || info.offset.y > 300) onClose()
          }}
          className="relative h-[100vh] w-full max-w-[500px] justify-center overflow-hidden bg-black"
          onClick={(e) => {
            e.stopPropagation()
            handleOutsideClick(e)
          }}
        >
          <div className="absolute left-0 right-0 top-0 z-50 bg-gradient-to-b from-black/60 via-black/40 to-transparent px-4 pb-4 pt-8">
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="flex items-center justify-between"
            >
              <div className="flex items-center gap-2">
                <div className="relative">
                  <div className="rounded-full bg-black p-[1px]">
                    <Avatar className="h-8 w-8 border-2 border-transparent">
                      <AvatarImage
                        src={
                          currentAccount.profilePicture || defaultAccountImage
                        }
                      />
                      <AvatarFallback>
                        {currentAccount?.displayName?.toUpperCase()}
                      </AvatarFallback>
                    </Avatar>
                  </div>
                </div>
                <span className="text-sm font-semibold text-white">
                  {currentAccount.displayName}
                </span>
              </div>

              <div className="flex items-center gap-1">
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full text-white transition-colors hover:bg-white/10"
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsPaused(!isPaused)
                  }}
                >
                  {isPaused ? (
                    <Play className="h-5 w-5" />
                  ) : (
                    <Pause className="h-5 w-5" />
                  )}
                </Button>

                {isOwner && (
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <Button
                      variant="ghost"
                      size="icon"
                      className="rounded-full text-white transition-colors hover:bg-white/10"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleOpenDeleteDialog()
                      }}
                    >
                      <Trash2 className="h-5 w-5" />
                    </Button>
                  </motion.div>
                )}

                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    variant="ghost"
                    size="icon"
                    className="rounded-full text-white transition-colors hover:bg-white/10"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsMuted(!isMuted)
                    }}
                  >
                    <motion.div
                      initial={false}
                      animate={{ rotate: isMuted ? 0 : 360 }}
                      transition={{ type: 'spring', duration: 0.5 }}
                    >
                      {isMuted ? (
                        <VolumeX className="h-5 w-5" />
                      ) : (
                        <Volume2 className="h-5 w-5" />
                      )}
                    </motion.div>
                  </Button>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    variant="ghost"
                    size="icon"
                    className="rounded-full text-white transition-colors hover:bg-white/10"
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose()
                    }}
                  >
                    <X className="h-5 w-5" />
                  </Button>
                </motion.div>
              </div>
            </motion.div>
          </div>

          {!!currentAccount.stories.length && (
            <Stories
              storyContainerStyles={{
                zIndex: 10,
              }}
              keyboardNavigation={true}
              stories={currentAccount.stories.map((s) => ({
                url: s.assets[0].url,
                type: s.assets[0].isVideo ? 'video' : 'image',
              }))}
              storyInnerContainerStyles={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              defaultInterval={15000}
              width={Math.min(500, width)}
              height={height}
              currentIndex={currentStoryIndex}
              onStoryEnd={handleNextStory}
              onNext={handleNextStory}
              onPrevious={handlePrevStory}
              isPaused={isPaused}
            />
          )}

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="absolute bottom-0 left-0 right-0 z-50 bg-gradient-to-t from-black via-black/70 to-transparent p-4"
          >
            <div className="flex items-center gap-2">
              <Input
                ref={inputRef}
                name={formHook.path.comment}
                formHook={formHook}
                placeholder={`Reply to ${currentAccount.displayName}...`}
                className="h-12 flex-1 rounded-full border-white/10 bg-white/10 px-6 transition-all duration-200 focus:ring-2 focus:ring-white/20"
                inputClassName="bg-transparent placeholder:text-white/50 text-white"
                onKeyDown={(e) => e.key === 'Enter' && handleReply()}
                onFocus={() => setIsPaused(true)}
                onBlur={() => {}}
              />
              <div className="flex gap-1">
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-12 w-12 rounded-full border border-white/10 bg-white/10 backdrop-blur-sm transition-all duration-200 hover:border-white/20 hover:bg-white/20"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleLike()
                    }}
                  >
                    <Heart className="h-6 w-6" />
                  </Button>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-12 w-12 rounded-full border border-white/10 bg-white/10 backdrop-blur-sm transition-all duration-200 hover:border-white/20 hover:bg-white/20"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleReply()
                    }}
                  >
                    <Send className="h-6 w-6 text-white" />
                  </Button>
                </motion.div>
              </div>
            </div>
          </motion.div>
          <YesNoModal
            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            onConfirm={handleConfirmDelete}
            title="¿Eliminar esta historia?"
            message="Esta acción no se puede deshacer."
            confirmText="Sí"
            cancelText="No"
          />
        </motion.div>
      </motion.div>
    )
  },
)

export default StoryViewer
