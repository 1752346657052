import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { Ids } from '@goatlab/js-utils'
import { cn } from '@src/utils/cn'
import { Plus } from 'lucide-react'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import type { RouterOutputs } from '@sodium/shared-frontend-services'
import { defaultProfileImg } from '@components/ui/images/images'
import { useWindowSize } from '@src/utils/use-window-size'

type Story = RouterOutputs['backend']['stories']['getStories']['data'][number]

interface StoriesRowProps {
  stories: Story[]
  onStoryClick: (index: number) => void
  handleCreateStoryDialog: () => void
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`
  }
  return text
}

export default function StoriesRow({
  stories,
  onStoryClick,
  handleCreateStoryDialog,
}: StoriesRowProps) {
  const { width } = useWindowSize()
  const calculatedMax = Math.round(width / 75) - 1
  const slidesPerView = Math.min(calculatedMax, 5)

  const uploadStory = () => {
    handleCreateStoryDialog()
  }

  return (
    <div className="w-full px-2 py-2">
      <Swiper
        spaceBetween={0}
        slidesPerView={slidesPerView}
        className="h-full w-full"
      >
        {stories.map((story, index) => (
          <SwiperSlide
            key={`story--user-key-${story.accountId}_${Ids.nanoId(4)}`}
            className="flex h-full flex-col items-center justify-center"
          >
            <button
              key={story.accountId}
              className="flex h-full w-full flex-col items-center justify-center gap-1"
              onClick={(e) => {
                e.stopPropagation()
                if (index === 0 && story.stories.length === 0)
                  return handleCreateStoryDialog()
                onStoryClick(index)
              }}
            >
              <div
                className={cn(
                  'rounded-full p-[2px]',
                  'bg-gradient-to-tr from-yellow-500 via-orange-500 to-pink-500',
                )}
              >
                <div className="relative">
                  <Avatar
                    className="flex items-center justify-center border-2 border-background"
                    style={{
                      height: 70,
                      width: 70,
                    }}
                  >
                    <AvatarImage
                      src={story.profilePicture || defaultProfileImg}
                    />
                    <AvatarFallback>
                      {story.displayName?.toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  {story.displayName === 'Your story' && (
                    <div
                      className="absolute -bottom-1 -right-1 flex h-7 w-7 items-center justify-center rounded-full border-2 border-white bg-primary text-primary-foreground"
                      onClick={(e) => {
                        e.stopPropagation()
                        uploadStory()
                      }}
                    >
                      <Plus className="h-5 w-5" />
                    </div>
                  )}
                </div>
              </div>
              <span className="w-full truncate text-center text-xs">
                {truncateText(story.displayName || '', 13)}
              </span>
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
