import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import type { VideoInputSettings } from '@src/utils/video/video.types'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import { VideoTrim } from '@components/ui/video/VideoTrim'
import { VideoFormats } from '@src/utils/video/video.types'
import { useDropzone } from 'react-dropzone'
import type { VideoUploadProps } from '../video/VideoUpload'

export interface PictureUploadProps {
  onUploadButtonPressed?: (props: VideoUploadProps) => void
  isLoading?: boolean
  cropResizable?: boolean
}

export const ImageOrVideoUpload: FC<PictureUploadProps> = ({
  onUploadButtonPressed,
  isLoading,
  cropResizable,
}) => {
  const cropperRef = useRef<HTMLImageElement>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [videoSettings, setVideoSettings] = useState<VideoInputSettings>({
    videoType: VideoFormats.mp4,
    customEndTime: 0,
    customStartTime: 0,
    removeAudio: false,
    url: null,
  })

  const { getRootProps, getInputProps, isFileDialogActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/x-matroska': ['.mkv'],
      'video/quicktime': ['.mov'],
      'video/x-msvideo': ['.avi'],
      'video/x-flv': ['.flv'],
    },
    maxSize: 50_000_000,
    multiple: false,
    onDrop: (files) => {
      setAcceptedFiles(files)
    },
  })

  const isVideo = acceptedFiles[0]?.type.startsWith('video/')

  const onUploadPressed = async () => {
    if (!acceptedFiles[0]) return

    if (isVideo) {
      onUploadButtonPressed?.({
        videoBlob: new Blob([acceptedFiles[0]], {
          type: acceptedFiles[0].type,
        }),
        fileName: acceptedFiles[0].name,
        mime: acceptedFiles[0].type,
        videoSettings,
      })
    } else {
      const cropper = (cropperRef?.current as any).cropper as Cropper
      cropper.getCroppedCanvas().toBlob((imageBlob) => {
        if (!imageBlob) {
          return
        }

        onUploadButtonPressed?.({
          videoBlob: imageBlob,
          fileName: acceptedFiles[0].name,
          mime: acceptedFiles[0].type,
          videoSettings,
        })
      })
    }
  }

  useEffect(() => {
    let videoUrl: string | null = null

    if (acceptedFiles[0]) {
      if (acceptedFiles[0].type.startsWith('video/')) {
        videoUrl = URL.createObjectURL(acceptedFiles[0])
        return setVideoSettings((settings) => ({
          ...settings,
          url: videoUrl,
        }))
      } else {
        return setVideoSettings((settings) => ({
          ...settings,
          url: null,
        }))
      }
    }

    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl)
      }
    }
  }, [acceptedFiles])

  return (
    <div className="relative flex w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white md:min-w-[600px]">
      <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
        <div>
          <div className="m-auto mt-16 max-w-2xl">
            <div className="px-4 text-center">
              {acceptedFiles?.length ? (
                isLoading ? (
                  <>Uploading {isVideo ? 'Video' : 'Picture'}...</>
                ) : isVideo ? (
                  <>
                    <video
                      ref={videoRef}
                      controls
                      src={videoSettings.url || ''}
                      className="mb-4 h-64 w-full object-contain"
                    />
                    <VideoTrim
                      videoSettings={videoSettings}
                      onVideoSettingsChange={(newVideoSettings) => {
                        setVideoSettings((previousSetting) => {
                          const parsedUrl = new URL(previousSetting.url || '')
                          const baseUrl = parsedUrl.href.split('#')[0]

                          const newUrl = `${baseUrl}#t=${Math.floor(newVideoSettings.customStartTime)},${Math.ceil(newVideoSettings.customEndTime)}`

                          return {
                            ...newVideoSettings,
                            url: newUrl,
                          }
                        })
                      }}
                      disable={isLoading || false}
                      videoRef={videoRef}
                    />
                  </>
                ) : (
                  <Cropper
                    src={URL.createObjectURL(acceptedFiles[0])}
                    style={{ height: 370, width: '100%' }}
                    aspectRatio={9 / 16}
                    autoCrop={true}
                    modal={true}
                    center={true}
                    zoomable={true}
                    cropBoxResizable={cropResizable ?? false}
                    cropBoxMovable={true}
                    guides={true}
                    ref={cropperRef}
                  />
                )
              ) : (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className="cursor-pointer rounded-lg bg-slate-100 p-6"
                >
                  <div className="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100">
                    {isFileDialogActive ? (
                      <SpinnerLoader text="" className="mr-4" />
                    ) : (
                      <svg className="h-6 w-5 fill-current" viewBox="0 0 20 24">
                        <path
                          className="text-slate-500"
                          d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                        />
                        <path
                          className="text-slate-300"
                          d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                        />
                        <path
                          className="text-slate-400"
                          d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                        />
                      </svg>
                    )}
                  </div>
                  <h2 className="mb-2 text-2xl font-bold text-slate-800">
                    Upload your video or image
                  </h2>
                  <div className="mb-6">
                    Click to select files or Drag drop some files here
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </div>
          </div>
        </div>

        {acceptedFiles?.length ? (
          <div className="-mb-6 mt-6 grid grid-flow-col justify-start sm:auto-cols-max sm:justify-end">
            <Button
              variant="default"
              onClick={onUploadPressed}
              loading={isLoading}
              color="black"
            >
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
