import type { MarketplaceAsset } from '@sodium/shared-schemas'
import { useCallback } from 'react'
import { backendHook } from '@api/trpc'
import { formatterAssets } from '@src/utils/chat/formatterAssets'
import type { ChatMessage } from '../store/ChatStore'

export const useSendStoryMessage = () => {
  const sendMessage = backendHook.backend.chats.sendMessage.useMutation()

  const onSend = useCallback(
    ({
      newMessages,
      storyAccountId,
      assets,
    }: {
      newMessages: ChatMessage[]
      storyAccountId: string
      assets: MarketplaceAsset[]
    }) => {
      newMessages.forEach((message) => {
        sendMessage.mutate({
          recipientId: storyAccountId,
          localId: message._id,
          message: `${message.text}`,
          assets: formatterAssets(assets),
          createdAt: message.createdAt.getTime(),
        })
      })
    },
    [],
  )

  return { onSend }
}
