import type { RouterOutputs } from '@api/trpc'
import type { FC } from 'react'
import { memo, useCallback, useEffect, useState } from 'react'
import { api } from '@api/api'
import { HomeStory } from '@components/story/HomeStory'
import { VirtualFeedWindow } from '@components/ui/VirtualFeedWindow'
import { useHomeFeedStore } from '@sodium/shared-frontend-schemas'
import { maxHomeFeedWidth } from '@src/consts'
import clsx from 'clsx'
import { HomePost } from './HomePost'
import { HomePostInput } from './HomePostInput'
import { Suggestions } from './Suggestions'

interface HomeProps {
  accountId?: string
  isViewable?: boolean
  currentIndex?: number
  showHeaderInput?: boolean
  shouldScroll?: boolean
  showAdvertisingPosts?: boolean
  showStories?: boolean
}

export type Post = RouterOutputs['backend']['posts']['homeFeed']['data'][0]

const HomePosts: FC<HomeProps> = memo(
  ({
    accountId,
    isViewable,
    currentIndex,
    showHeaderInput = true,
    shouldScroll = false,
    showAdvertisingPosts = false,
    showStories = true,
  }) => {
    const [isShowPostModal, setIsShowPostModal] = useState(false)
    const { setVisibleAccountId } = useHomeFeedStore()

    useEffect(() => {
      setVisibleAccountId(accountId || '')
    }, [accountId])

    const showPostModal = async () => {
      setIsShowPostModal(true)
    }

    const { getHomeFeedHook, getAccountFeedHook } = api.hooks.useHomePostFeed({
      accountId,
    })

    const getAdvertisingAccountFeedHook =
      api.posts.useAdvertisingAccountFeedInfiniteQuery({
        enabled: showAdvertisingPosts,
      })

    const renderActivity = useCallback(
      ({ item }: { item: Post }) => {
        return (
          <HomePost
            isPreview={false}
            isViewable={isViewable}
            post={item}
            showPostModal={showPostModal}
          />
        )
      },
      [
        getHomeFeedHook.data,
        getAccountFeedHook.data,
        getAdvertisingAccountFeedHook.data,
        isViewable,
      ],
    )

    const postsQueryHook = accountId
      ? getAccountFeedHook
      : showAdvertisingPosts
        ? getAdvertisingAccountFeedHook
        : getHomeFeedHook

    return (
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-2">
          {/* Main Home Feed */}
          <div
            className={clsx(
              'scrollbar-hide col-span-full sm:col-span-12 sm:col-start-2 xl:col-span-6 xl:col-start-2',
            )}
            style={{
              maxWidth: maxHomeFeedWidth,
            }}
          >
            {showStories && <HomeStory />}

            {showHeaderInput && (
              <HomePostInput
                isDialogOpen={isShowPostModal}
                setIsDialogOpen={setIsShowPostModal}
              />
            )}
            <VirtualFeedWindow
              scrollToIndex={shouldScroll ? currentIndex : undefined}
              infiniteQuery={postsQueryHook}
              renderItem={renderActivity}
            />
          </div>

          {/* Suggestions */}
          <div className="hidden items-start sm:col-span-3 sm:col-start-10 xl:col-span-3 xl:inline-grid">
            <div className="self-start rounded-lg bg-white p-4 shadow-md">
              <Suggestions />
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default HomePosts
