import type { ChatMessage } from '@components/ui/chat/store/ChatStore'
import { useCallback, useRef, useState } from 'react'
import { api } from '@api/api'
import { backendHook } from '@api/trpc'
import { useSendStoryMessage } from '@components/ui/chat/hooks/useSendStoryMessage'
import { Ids } from '@goatlab/js-utils'
import { useQueryRefresh } from '@sodium/shared-frontend-schemas'
import type { StoryViewerRef } from './StoryViewer'
import { CreateStoryDialog } from './CreateStoryDialog'
import StoriesRow from './StoriesRow'
import StoryViewer from './StoryViewer'

export const HomeStory = () => {
  const utils = backendHook.useUtils()
  const { isLoading, data, refetch } = api.stories.useUserStoryQuery()
  const userStories = data?.pages.flatMap((p) => p.data) || []
  const { onSend } = useSendStoryMessage()
  const [activeAccountStoryIndex, setActiveAccountStoryIndex] = useState<
    number | null
  >(null)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const viewerRef = useRef<StoryViewerRef>(null)

  const handleCreateStoryDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleStoryClick = (index: number) => {
    setActiveAccountStoryIndex(index)
    setIsViewerOpen(true)
  }

  const handleCloseViewer = () => {
    setIsViewerOpen(false)
    setActiveAccountStoryIndex(null)
  }

  const handleStoryComplete = () => {
    // Lógica para marcar historias como vistas si es necesario
  }

  const handleLike = () => {
    // Lógica para manejar likes si es necesario
  }

  const { handleRefresh } = useQueryRefresh([
    api.posts.useHomeFeedInfiniteQuery().refetch,
    refetch,
    utils.backend.chats.getUnreadConversationsCount.refetch,
    utils.backend.friendship.getFriendshipNotificationsCount.refetch,
    utils.backend.posts.getPostComments.invalidate,
    utils.backend.posts.getAccountsThatLikedPost.invalidate,
  ])

  const deleteStoryHook =
    backendHook.backend.stories.deleteStoryById.useMutation({
      onSettled: async () => {
        await handleRefresh() //  Solo actualizamos los datos, la navegación la maneja StoryViewer
      },
    })

  const handleDeleteStory = useCallback(
    async (storyId: string) => {
      try {
        await deleteStoryHook.mutateAsync({ storyId })
      } catch (err) {
        console.error('Error deleting story:', err)
      }
    },
    [deleteStoryHook],
  )

  const handleReply = (storyId: string, comment: string, userId: string) => {
    if (!comment || !storyId || !userId) return

    const user = userStories.find((user) => user.accountId === userId)
    const story = user?.stories?.find((s) => s.id === storyId)

    if (!story || !user) return

    const asset = story.assets[0]

    const mes: ChatMessage = {
      _id: Ids.uuid(),
      text: `<div>${comment}</div>`,
      createdAt: new Date(),
      image: asset?.thumbnailUrl,
      user: {
        _id: user.accountId || 'user-id',
        avatar: user.profilePicture || '',
        name: user.displayName || '',
      },
    }

    onSend({
      newMessages: [mes],
      storyAccountId: user.accountId,
      assets: [asset],
    })
  }

  if (isLoading) {
    return <div className="pt-12"></div>
  }

  return (
    <div className="mx-auto mb-2 w-full max-w-2xl flex-row bg-white">
      <StoriesRow
        stories={userStories}
        onStoryClick={handleStoryClick}
        handleCreateStoryDialog={handleCreateStoryDialog}
      />

      {isViewerOpen && activeAccountStoryIndex !== null && (
        <StoryViewer
          ref={viewerRef}
          stories={userStories}
          initialAccountStoryIndex={activeAccountStoryIndex}
          onClose={handleCloseViewer}
          onStoryComplete={handleStoryComplete}
          onLike={handleLike}
          onReply={handleReply}
          onDelete={handleDeleteStory}
        />
      )}
      <CreateStoryDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </div>
  )
}
